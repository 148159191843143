import { Routes, Route } from "react-router-dom";
import Admin from "../scenes/Admin/dashboard/index";
// import ShopCategory from "../scenes/Shop/ShopCategory/index";
// import Cart from "../scenes/Shop/Cart/index";
// import LoginSignup from "../scenes/Shop/LoginSignup/LoginSignup";
import LoginAdmin from "../scenes/Admin/Login/LoginAdmin";
import ProtectedRouter from "./ProtectedRouter";
// import LoginClient from "../scenes/Shop/Login/LoginClient";
// import Checkout from "../scenes/Shop/Checkout";
// import ProductDetail from "../scenes/Shop/ProductDetail";
// import ProtectedRouterClient from "./ProtectedRouterClient";
// import CheckoutStatus from "../scenes/Shop/Checkout/checkoutStatus";
const Routers = () => {
  return (
    <Routes >
      {/* <Route path="/admin" element={<Home />} /> */}
      <Route
        path="/*"
        element={
          <ProtectedRouter allowedRoles={"admin"}>
            <Admin />
          </ProtectedRouter>
        }
      />
      {/* <Route path="/shopcategory" element={<ShopCategory />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/login" element={<LoginClient />} />
      <Route path="/register" element={<LoginSignup />} /> */}
      <Route path="/loginadmin" element={<LoginAdmin />} />
      {/* <Route
        path="/checkout"
        element={
          <ProtectedRouterClient allowedRoles={"user"}>
            <Checkout />
          </ProtectedRouterClient>
        }
      />
      <Route path="/productDetail/:id" element={<ProductDetail />} />
      <Route path="/checkoutStatus" element={<CheckoutStatus />} /> */}
    </Routes>
  );
};

export default Routers;
