import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../../components/Header/Header.jsx";
import EditTruyenForm from "./EditTruyen.jsx";
import AddTruyenForm from "./AddTruyen.jsx";
import { BASE_URL } from "../../../config.js";

const Products = () => {
  const [data, setData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [change, setChange] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0); // current page, 0-indexed
  const [pageSize, setPageSize] = useState(10); // number of rows per page
  const [totalRows, setTotalRows] = useState(0); // total number of rows in the dataset

  const fetchData = async (isPageZero = false) => {
    const result = await fetch(
      `${BASE_URL}/get-truyen?page=${(isPageZero ? 0 : page) + 1}&limit=${pageSize}&keyword=${searchQuery}`
    );
    const data = await result.json();
    setPage(isPageZero ? 0 : page)
    setData(data.truyens);
    setTotalRows(data.totalPages * pageSize); // Correctly set total rows
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, change]);

  useEffect(() => {
    if (searchQuery)
      fetchData(true);
  }, [searchQuery]);

  const updateTruyenHandle = async (updatedItem) => {
    const res = await fetch(`${BASE_URL}/update-truyen`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedItem),
    });
    setChange(!change);
    handleCloseEditDialog();
  };

  const handleDelete = async () => {
    const res = await fetch(`${BASE_URL}/delete-truyen/${deleteId}`, {
      method: "DELETE",
    });
    setChange(!change);
    handleCloseDeleteDialog(false);
  };

  const handleClickOpenEditDialog = (item) => {
    setEditItem(item);
    setOpenEditDialog(true);
  };

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleClickOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleAddTruyen = async (data) => {
    const res = await fetch(`${BASE_URL}/inset-truyen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    setChange(!change);
    setOpenCreateDialog(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const TruncatedCell = ({ description }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };

    const renderContent = () => {
      if (isExpanded) {
        return (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        );
      } else {
        const truncatedText = description.substring(0, 100) + "...";
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: truncatedText }} />
            <button
              onClick={handleToggle}
              style={{
                color: "blue",
                textDecoration: "underline",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: 0,
                fontSize: "inherit",
              }}
            >
              Show more
            </button>
          </div>
        );
      }
    };

    return (
      <TableCell>
        {renderContent()}
      </TableCell>
    );
  };
  return (
    <Box m="20px">
      <Header title="Truyện" subtitle="Quản lý truyện" />
      <TextField
        variant="outlined"
        placeholder="Tìm kiếm truyện theo tên..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        fullWidth
        sx={{ mt: 2, mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpenCreateDialog}
        sx={{
          mt: 2,
          mb: 4,
          backgroundColor: "secondary.main",
        }}
      >
        Thêm truyện
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell>Hình ảnh</TableCell>
              <TableCell>Thể loại</TableCell>
              <TableCell>Tác giả</TableCell>
              <TableCell>Mô tả</TableCell>
              <TableCell>Hành động</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/files/image/${row.images}`}
                    alt={row.name}
                    style={{ width: "50px", borderRadius: "50%" }}
                  />
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.auth}</TableCell>
                {
                  //   <TableCell>
                  //   {row.description.length > 50 ? (
                  //     <>
                  //       {row.description.slice(0, 50)}...
                  //       {/* Tooltip for full description */}
                  //       <Typography
                  //         variant="body2"
                  //         sx={{ cursor: "pointer", display: "inline", ml: 1 }}
                  //         onClick={() => alert(row.description)}
                  //       >
                  //         Read more
                  //       </Typography>
                  //     </>
                  //   ) : (
                  //     row.description
                  //   )}
                  // </TableCell>
                }
                <TruncatedCell description={row.description} />
                <TableCell>
                  <Box display="flex" justifyContent="center">
                    <IconButton
                      onClick={() => handleClickOpenEditDialog(row)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleClickOpenDeleteDialog(row._id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </TableContainer>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogContent>
          <EditTruyenForm
            productData={editItem}
            updateTruyenHandle={updateTruyenHandle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogContent>
          <AddTruyenForm createTruyen={handleAddTruyen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Có chắc là muốn xoá không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Products;