import { Box, Button, TextField } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header/Header";
import { useState } from "react";

import { BASE_URL, token } from "../../../config";

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  description: yup.string().required("required"),
  auth: yup.string().required("required"),
  type: yup.string().required("required"),
});

const EditForm = ({ productData, updateTruyenHandle }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (values) => {
    try {
      let imageUrl = values.images; // Use existing image URL if no new file is selected

      if (selectedFile) {
        const formData = new FormData();
        formData.append("image", selectedFile);

        // Upload the image to the server
        const response = await fetch(
          `${BASE_URL}/files/upload/${productData.id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`, // Assuming a Bearer token for authentication
            },
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to upload image");
        }

        const data = await response.json();
        imageUrl = data.filePath; // Set the new image URL
      }
      // Convert star to number and update the values object
      values.star = Number(values.star);
      values.images = imageUrl;
      console.log(values);

      // Call the function to update the product data
      updateTruyenHandle(values);
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };

  return (
    <Box m="20px">
      <Header title="Cập nhật truyện" subtitle="Cập nhật truyện" />
      <Formik
        initialValues={productData}
        validationSchema={checkoutSchema}
        onSubmit={handleSubmit}
      >
        <Form style={{ width: "500px" }}>
          <Box display="flex" flexDirection={"column"} m={2} gap={2}>
            <Field
              as={TextField}
              fullWidth
              variant="filled"
              label="ID"
              name="id"
              disabled
            />
            <Field
              as={TextField}
              fullWidth
              label="Tên"
              name="name"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Mô tả"
              name="description"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Sao"
              name="star"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Dịch giả"
              name="dich_gia"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Thể loại"
              name="type"
              placeholder="Ngôn tình, Tu tiên"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Trạng thái"
              placeholder="Đang ra, Hoàn thành"
              name="status"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Tác giả"
              name="auth"
              variant="outlined"
            />
            <Field
              as={TextField}
              fullWidth
              label="Hình ảnh"
              name="images"
              variant="outlined"
              disabled
            />
            <input type="file" onChange={handleFileChange} />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Cập nhật truyện
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default EditForm;
