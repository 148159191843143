import Routers from "../routes/Routers";

const Layout = () => {
  return (
    <main>
      <Routers />
    </main>
  );
};

export default Layout;
