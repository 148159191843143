import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header/Header.jsx";
import { useState } from "react";
import { getBase64 } from "../../../utils/base64.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddForm = ({ createTruyen }) => {

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    id: yup.string().required("required"),
    description: yup.string().required("required"),
    type: yup.string().required("required"),
    auth: yup.string().required("required"),
    images: yup.string().required("required"),
    star: yup.string().required("required"),
  });

  const initialValues = {
    id: "",
    name: "",
    description: "",
    star: 0,
    type: "",
    auth: "",
    images: "",
  };

  return (
    <Box m={2}>
      <Header title="Thêm truyện" subtitle="" />
      <Formik
        onSubmit={(values) => {
          values.star = Number(values.star);
          createTruyen(values);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {() => (
          <Form style={{ width: "500px" }}>
            <Box display="flex" flexDirection={"column"} m={2} gap={2}>
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                label="ID"
                name="id"
              />
              <Field
                as={TextField}
                fullWidth
                label="Tên"
                name="name"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Mô tả"
                name="description"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Sao"
                name="star"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Dịch giả"
                name="dich_gia"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Thể loại"
                name="type"
                placeholder="Ngôn tình, Tu tiên"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Trạng thái"
                placeholder="Đang ra, Hoàn thành"
                name="status"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Tác giả"
                name="auth"
                variant="outlined"
              />
              <Field
                as={TextField}
                fullWidth
                label="Hình ảnh"
                name="images"
                variant="outlined"
              />
            </Box>

            <Box display="flex" justifyContent="end" mt={2}>
              <Button type="submit" color="secondary" variant="contained">
                Thêm truyện
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddForm;
